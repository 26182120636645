exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-grafika-js": () => import("./../../../src/pages/grafika.js" /* webpackChunkName: "component---src-pages-grafika-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pozycjonowanie-stron-js": () => import("./../../../src/pages/pozycjonowanie-stron.js" /* webpackChunkName: "component---src-pages-pozycjonowanie-stron-js" */),
  "component---src-pages-sklepy-internetowe-js": () => import("./../../../src/pages/sklepy-internetowe.js" /* webpackChunkName: "component---src-pages-sklepy-internetowe-js" */),
  "component---src-pages-strony-internetowe-js": () => import("./../../../src/pages/strony-internetowe.js" /* webpackChunkName: "component---src-pages-strony-internetowe-js" */),
  "component---src-pages-testowanie-js": () => import("./../../../src/pages/testowanie.js" /* webpackChunkName: "component---src-pages-testowanie-js" */)
}

